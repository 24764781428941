.msu-footer{
	padding-top:20px;
	padding-bottom:40px;
	a{
		color:$drkgreen;
		&:hover,&:focus{
			color:#618A48;
		}
	}
	.msu-wordmark{
		text-align: center;
		margin-bottom:15px;
		img{
			max-width:100%;
		}
	}
	.msu-links{}
	.msu-site-links{
		ul{
			display:inline-block;
			list-style: none;
			padding:0 0 5px 0px;
			margin:0px;
			border-bottom:1px dotted;
			li{
				display:inline;
				padding:0px 10px;
				line-height:0.8em;
				border-left:1px solid $oil;
				&:nth-child(1){
					padding-left:0px;
					border-left:0px;
				}
			}
		}
	}
	.msu-info{
		ul{
			display:inline-block;
			list-style: none;
			padding:5px 0px 0px;
			margin:0px;
			font-size: 0.8em;
			li{
				display:inline;
				padding:0px 10px;
				line-height:0.8em;
				border-left:1px solid $oil;
				&:nth-child(1){
					padding-left:0px;
					border-left:0px;
				}
				&:nth-child(2){
					a{
						color:#618A48;
					}
				}
				.msu-phone{
					color:#618A48;
				}
			}
			.spartans-will{
				font-weight:bold;
				text-transform: uppercase;
			}
		}
	}

}

.back-top {
    display: block;
    background: #444444;
    border-radius: 5px;
    font-size: 1.6em;
    position: fixed;
    z-index: 100;
    bottom: 0;
    right: 15px;
    padding: 5px 25px 10px;
    color: #ffffff;
    -webkit-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    opacity: 0;
    visibility: hidden;
    &.visible {
    	opacity: .50;
    	bottom: 10px;
    	visibility: visible;
	}
	&:hover,&:focus{
		opacity: .80;
		background: #222222;
	    color: #ffffff;
	}
}