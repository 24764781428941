body{
	line-height: 1.6em;
	background: $color-bg-01;
}

a::-moz-focus-inner {
  border: 0;
}
a:focus {
    outline: none;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
}