.msu-masthead{
  background:#efefef;
  padding:10px 0px;
  height:56px;
  overflow:hidden;
}
.msu-logo{
  text-align:center;
  @media only screen and (min-width: 768px) {
    text-align: left;
  }
  img{
    max-width:100%;
    height:auto;
  }
}
.msu-search-bar {
  form{
    width: auto;
    border: 0;
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    .form-group{
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle;
    }
    .form-control{
      height:34px;
    }
  }
  .btn{
    padding: 6px 18px 5px;
  }
  .btn-search{
      color: #222;
      background-color: #fff;
      border-color: #ccc;
  }
}

.dept-masthead{
  background-color: #8CA84B;
  padding:15px 0px;
  height:112px;
  overflow: hidden;
  .dept-logo{
    display:block;
    color:#ffffff;
    text-decoration: none;
    @media only screen and (min-width: 768px) {
      margin-left:15px;
    }
    &:hover,&:focus{
      text-decoration: none;
    }
    h1{
      font-size:2em;
      text-align:center;
      font-family:"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight:900;
      text-transform: uppercase;
      margin:0 0 10px 0;
      padding:0px;
      letter-spacing: -1px;
      @media only screen and (min-width: 768px) {
        text-align:left;
        margin:0px;
      }
    }
    h2{
      font-size:2.2em;
      text-align:center;
      font-family:"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight:900;
      text-transform: uppercase;
      margin:0px;
      padding:0px;
      letter-spacing: -1px;
      @media only screen and (min-width: 768px) {
        font-size:3em;
        text-align:left
      }
    }
  }
}

.navbar-search{
  padding-top:15px;
  .form-control{
    width:85%;
  }
  .btn{
    width:15%;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}





/* -------------------------------- 

1. Auto-Hiding Navigation - Simple

-------------------------------- */

$header-height: 170px;
$header-padding: 0px;
$masthead-height: ($header-height - $header-padding);
$main-height: ($header-height+$header-padding+55px);


.main{
  margin-top: $main-height;
}
.auto-hide-header {
  padding-top:$header-padding;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: $header-height;
  background-color: #ffffff;
  /* Force Hardware Acceleration */
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  will-change: transform;
  -webkit-transition: -webkit-transform .5s;
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s, -webkit-transform .5s;
  &:after {
    clear: both;
    content: "";
    display: block;
  }
  &.is-hidden {
    -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  @media only screen and (min-width: 1160px) {
    //height: 80px;
  }

  .masthead{
    height:$masthead-height;
  }
  .logo{
    a{
      margin-top:15px;
    }
    img{
      display:block;
    }
  }
  .nav-trigger{
    /* vertically align its content */
    display: table;
    height: 100%;
    padding: 0 1em;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: #25283D;
    font-weight: bold;
    right: 0;
    border-left: 1px solid #f2f2f2;
    @media only screen and (min-width: 1024px) {
        display: none;
    }
    span {
      /* vertically align inside parent element */
      display: table-cell;
      vertical-align: middle;
    }
    em, em::after, em::before{
      display: block;
      position: relative;
      height: 2px;
      width: 22px;
      background-color: #25283D;
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
    }
    em {
      /* this is the menu central line */
      margin: 6px auto 14px;
      -webkit-transition: background-color .2s;
      transition: background-color .2s;
    }
    em::before, em::after {
      position: absolute;
      content: '';
      left: 0;
      -webkit-transition: -webkit-transform .2s;
      transition: -webkit-transform .2s;
      transition: transform .2s;
      transition: transform .2s, -webkit-transform .2s;
    }
    em::before {
      /* this is the menu icon top line */
      -webkit-transform: translateY(-6px);
          -ms-transform: translateY(-6px);
              transform: translateY(-6px);
    }
    em::after {
      /* this is the menu icon bottom line */
      -webkit-transform: translateY(6px);
          -ms-transform: translateY(6px);
              transform: translateY(6px);
    }
  }
}
.auto-hide-header.nav-open .nav-trigger em {
  /* transform menu icon into a 'X' icon */
  background-color: rgba(255, 255, 255, 0);
}
.auto-hide-header.nav-open .nav-trigger em::before {
  /* rotate top line */
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.auto-hide-header.nav-open .nav-trigger em::after {
  /* rotate bottom line */
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}

/* -------------------------------- 

2. Auto-Hiding Navigation - Sub Nav

-------------------------------- */
.primary-nav {
  position: relative;
  z-index: 1;
  clear: both;
  // width: 100%;
  // height: 50px;
  // background-color: #25283D;
  /* Force Hardware Acceleration */
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  will-change: transform;
  -webkit-transition: -webkit-transform .5s;
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s, -webkit-transform .5s;
  .navbar-left{
    margin-left:-15px;
  }
}
.navbar-brand{
  font-weight:600;
}
.navbar-toggle .icon-bar {
  background:$link-color;
}

.navbar-msuhr{
  background:#fff;
  border-bottom:1px solid #dedede;
  .navbar-nav > li > a {
    color:#333333;
    font-weight:400;
    @media only screen and (min-width: 768px) {
      display: table-cell;
      vertical-align: middle;
      height:60px;
      border-left:1px solid #dedede;
      padding-top: 0px;
      padding-bottom: 0px;

      font-size:1.2em;
      line-height:1.2em;
      max-width:148px;
    }
    @media only screen and (min-width: 992px) {
      font-size:1.6em;
      line-height:1.2em;
      max-width:190px;
      padding-left:15px;
      padding-right:15px;
    }
    @media only screen and (min-width: 1200px) {
      padding-left:20px;
      padding-right:20px;
      max-width:200px;
    }
    &:hover,&:focus{
      color: #007573;
    }
  }
  .navbar-nav > li.active > a {
    color: #007573;
    font-weight:bold;
  }
  .navbar-nav > li.navbar-emf{
    background:#007573;
    &:hover,&:focus{
      background:#138F8C;
    }
    a{
      color:#ffffff;
      &:hover,&:focus{
        background:transparent;
      }
    }
    @media only screen and (min-width: 768px) {
      display:inline-block;
      transition: 0.3s;
      transform: skew(-20deg);  /* SKEW */
      background:#007573;
      &:hover,&:focus{
        background:#138F8C;
        transition: 0.3s;
        transform: skew(0deg);  /* SKEW */
      }
      > a {
        color:#ffffff;
        font-weight:900;
        text-transform: uppercase;
        transition: 0.3s;
        transform: skew(20deg);  /* SKEW */
        border-left:0px;
        padding-left: 20px;
        padding-right: 22px;
        @media only screen and (min-width: 992px) {
          padding-left: 35px;
          padding-right: 35px;
        }
        &:hover,&:focus{
          background:transparent;
          transition: 0.3s;
          transform: skew(0deg);  /* SKEW */
        }
      }
    }
  }
}
