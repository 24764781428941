.bs-component{
	clear:both;
}

.breadcrumb{
	background:transparent;;
	border:0px;
	padding:0px;
	font-weight:bold;
	font-size:0.8em;
	text-transform: capitalize;
	> li + li::before{
		content: "> ";
		font-weight:bold;
		padding: 0 5px;
		color: #666666;
	}
}

.btn-facebook{
	color: #FFFFFF;
	background-color: $color-facebook;
	border-color: darken($color-facebook, 10%);
	&:hover,&:focus{
		color: #FFFFFF;
		background-color: darken($color-facebook, 10%);
	}
}

.btn-twitter{
	color: #FFFFFF;
	background-color: $color-twitter;
	border-color: darken($color-twitter, 10%);
	&:hover,&:focus{
		color: #FFFFFF;
		background-color: darken($color-twitter, 10%);
	}
}

.btn-linkedin{
	color: #FFFFFF;
	background-color: $color-linkedin;
	border-color: darken($color-linkedin, 10%);
	&:hover,&:focus{
		color: #FFFFFF;
		background-color: darken($color-linkedin, 10%);
	}
}

.btn-msuhr{
	color: $drkgreen;
	background-color: $white;
	border-color: darken($drkgreen, 10%);
	padding-left:25px;
	padding-right:25px;
	text-transform: uppercase;
	&:hover,&:focus{
		color: #FFFFFF;
		background-color: $ltgreen;
		background-color: darken($ltgreen, 10%);
	}
}
.btn-msuhr-inverse{
	color: $white;
	background-color: $drkgreen;
	border-color: darken($drkgreen, 10%);
	padding-left:25px;
	padding-right:25px;
	text-transform: uppercase;
	&:hover,&:focus{
		color: #FFFFFF;
		background-color: $ltgreen;
		background-color: darken($ltgreen, 10%);
	}
}

/*
 * Specific home page styling
 */
 .hp-blocks{
 	.block-col{
 		text-align:center;
 		h3{
 			font-size:1.4em;
 			font-weight:900;
 			font-family: "Open Sans"; 
 			color: $drkgreen;
 		}
 		margin-bottom:25px;
 		@media only screen and (min-width: 992px) {
 			margin-bottom:0px;
 		}
 	}
 	.block-icon .fa-circle{
 		color:$ltgreen;
 	}
 	.block-content{
 		padding:10px 15px 20px;
 	}
 }

.hp-askHR{
	.col-left{
		text-align: center;
		padding-bottom:25px;
		@media only screen and (min-width: 768px) {
			padding-bottom:0px;
		}
	}
	.col-right{
		font-size:1.0em;
		line-height:1.6em;
		@media only screen and (min-width: 992px) {
			font-size:1.2em;
		}
	}
}


.hp-content{
	.col-welcome{
		font-size:1.2em;
		line-height:1.6em;
		padding-bottom:15px;
		img{
			width: 100%;
		}
	}
	.col-news{
		h2{
			margin-top:0px;
		}
	}
	.col-news-featured{
		img{
			padding-bottom:15px;
			width:100%;
		}
		h3{
			font-size:1.2em;
			font-weight:600;
		}
		padding-bottom:15px;
	}
	.col-news-list{
		ul.news-list{
			list-style: none;
			padding:0px;
			margin:0px;
			h3{
				font-size:1.0em;
				line-height: 1.4em;
				padding:0px;
				margin:0px;
			}
			li{
				margin-bottom:15px;
			}
		}
	}
}