.colophon{
	padding-top:40px;
	padding-bottom:40px;
	clear:both;
	background:$color-bg-02;
	.colo-col{
		margin-bottom:35px;
		text-align:center;
		&:nth-child(3){
			margin-bottom:0px;
		}
		@media only screen and (min-width: 768px) {
			margin-bottom:0px;
			text-align:left;
		}
		h3{
			margin-top:0px;
			color:$drkgreen;
			text-transform: uppercase;
			font-family: 'Raleway', sans-serif;
			font-weight:500;
		}
		a{
			color:$drkgreen;
		}
	}
	.colo-menu, .colo-list{
		list-style-type: none;
		margin-left:0px;
		padding-left:0px;
		> li{
			font-family: 'Raleway', sans-serif;
			font-size:1.2em;
			line-height:2em;
		}
	}
	.colo-2{
		@media only screen and (min-width: 990px) {
			border-left: 1px solid $drkgreen;
			border-right: 1px solid $drkgreen;
		}
		h3{
			text-align: center;
		}
	}
	.colo-social-links{
		list-style-type: none;
		padding:0px;
		width:100%;
		text-align: center;
		> li{
			display: inline-table;
			padding:10px;
		}
		a{
			display:block;
			border:3px solid $drkgreen;
			border-radius: 50%;
			padding:0px;
			background:$white;
			&:hover{
				background:$drkgreen;
			}
			&:hover{
				.fa-stack-1x{
					color:$white;
				}
			}
		}
	}
	.twitter-feed{
		
		@media only screen and (min-width: 990px) {
			margin-left:45px;
			margin-right:45px;
		}
	}
}