img{
    max-width:100%;
    height:auto;
}

.intro,.intro-txt{
  font-size:1.2em;
  line-height: 1.6em;
}

.step-title, .divider-title, .divider-title-light{
  text-align:center;
  background: $color-bg-01 url('../images/headline-bg-01.jpg') no-repeat center center;
  background-size:contain;
  color:$primary-color;
  font-weight:bold;
  text-transform: uppercase;
  font-size:1.6em;
  span{
    padding-left:15px;
    padding-right:15px;
    background: $color-bg-01;
  }
}
.divider-title-dark{
  text-align:center;
  background: $color-bg-02 url('../images/headline-bg-02.png') no-repeat center center;
  background-size:contain;
  color:$primary-color;
  font-weight:bold;
  text-transform: uppercase;
  font-size:1.6em;
  span{
    padding-left:15px;
    padding-right:15px;
    background: $color-bg-02;
  }
}

.bg-color-01, .bg-color-light{
    background-color:$color-bg-01;
}

.bg-color-02, .bg-color-dark{
    background-color:$color-bg-02;
}
.bg-fade-light{
  background:rgba(255,255,255,0.85);
}
.bg-fade-dark{
  background:rgba(0,0,0,0.85);
  color:#dddddd;
}
.clear-pad{
    margin-left: -15px;
    margin-right: -15px;
}

/* Availible styles for adding top and bottom padding */
.padding-none {
  padding-top: 0px;
  padding-bottom: 0px;
}
.padding-xs {
  padding-top: 5px;
  padding-bottom: 5px;
}
.padding-sm {
  padding-top: 10px;
  padding-bottom: 10px;
}
@media only screen and (min-width: 767px) {
  .padding-sm {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.padding-md {
  padding-top: 15px;
  padding-bottom: 15px;
}
@media only screen and (min-width: 767px) {
  .padding-md {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}
.padding-lg {
  padding-top: 20px;
  padding-bottom: 20px;
}
@media only screen and (min-width: 767px) {
  .padding-lg {
    padding-top: 65px;
    padding-bottom: 65px;
  }
}
.padding-xl {
  padding-top: 25px;
  padding-bottom: 25px;
}
@media only screen and (min-width: 767px) {
  .padding-xl {
    padding-top: 125px;
    padding-bottom: 125px;
  }
}
.padding-xxl {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media only screen and (min-width: 767px) {
  .padding-xxl {
    padding-top: 250px;
    padding-bottom: 250px;
  }
}

/* Available styles for adding top padding */

.padding-top-none {
  padding-top: 0px;
}
.padding-top-xs {
  padding-top: 5px;
}
.padding-top-sm {
  padding-top: 10px;
}
@media only screen and (min-width: 767px) {
  .padding-top-sm {
    padding-top: 20px;
  }
}
.padding-top-md {
  padding-top: 15px;
}
@media only screen and (min-width: 767px) {
  .padding-top-md {
    padding-top: 35px;
  }
}
.padding-top-lg {
  padding-top: 20px;
}
@media only screen and (min-width: 767px) {
  .padding-top-lg {
    padding-top: 65px;
  }
}
.padding-top-xl {
  padding-top: 25px;
}
@media only screen and (min-width: 767px) {
  .padding-top-xl {
    padding-top: 125px;
  }
}
.padding-top-xxl {
  padding-top: 50px;
}
@media only screen and (min-width: 767px) {
  .padding-top-xxl {
    padding-top: 250px;
  }
}

/* Available styles for adding bottom padding */

.padding-bottom-none {
  padding-bottom: 0px;
}
.padding-bottom-xs {
  padding-bottom: 5px;
}
.padding-bottom-sm {
  padding-bottom: 10px;
}
@media only screen and (min-width: 767px) {
  .padding-bottom-sm {
    padding-bottom: 20px;
  }
}
.padding-bottom-md {
  padding-bottom: 15px;
}
@media only screen and (min-width: 767px) {
  .padding-bottom-md {
    padding-bottom: 35px;
  }
}
.padding-bottom-lg {
  padding-bottom: 20px;
}
@media only screen and (min-width: 767px) {
  .padding-bottom-lg {
    padding-bottom: 65px;
  }
}
.padding-bottom-xl {
  padding-bottom: 25px;
}
@media only screen and (min-width: 767px) {
  .padding-bottom-xl {
    padding-bottom: 125px;
  }
}
.padding-bottom-xxl {
  padding-bottom: 50px;
}
@media only screen and (min-width: 767px) {
  .padding-bottom-xxl {
    padding-bottom: 250px;
  }
}