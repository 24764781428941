.carousel-msuhr{
	.carousel-control{
		z-index:2;
		background:rgba(255,255,255,0.75);
		height:100px;
		top:0px;
		width:30px;
	}
	@media only screen and (min-width: 361px) {
		.carousel-control{
			top:50px;
		}
	}
	.carousel-control.right .glyphicon{
		right:10px;
		margin-top:-16px;
	}
	.carousel-control.left .glyphicon{
		left:10px;
		margin-top:-16px;
	}

	.carousel-caption{
		z-index:1;
		position:relative;
		top:0px;
		left:0;
		right:0;
		padding:5px 10px 40px;
		background:rgba(0,0,0,0.5);

	}
	@media only screen and (min-width: 992px) {
		.carousel-control{
			background:rgba(255,255,255,0.75);
			height:100px;
			top:50%;
			margin-top:-50px;
			width:30px;
		}
		.carousel-control.right .glyphicon{
			right:10px;
			margin-top:-16px;
		}
		.carousel-control.left .glyphicon{
			left:10px;
			margin-top:-16px;
		}
		.carousel-caption{
			z-index:1;
			position:absolute;
			width:320px;
			background:rgba(0,0,0,0.5);
			text-align:left;
			top:0px;
			right:0px;
			bottom:0px;
			left:auto;
			padding:50px 50px 25px 25px;
			h3{
				font-family: "Open Sans";
				font-weight:900;
			}
		}
		.carousel-caption-right{

		}
		.carousel-caption-left{
			top:0px;
			right:auto;
			bottom:0px;
			left:0px;
			padding:50px 25px 25px 50px;
		}
	}
	.btn-carousel{
		border:1px solid #ffffff;
		background:transparent;
		color:#ffffff;
		padding-left:25px;
		padding-right:25px;
		text-transform: uppercase;
		&:hover,&:focus{
			background: #ff0000;//rgba(255,255,255,0.5);
		}
	}
	.carousel-indicators {
	    bottom: 0px;
	}
}

.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }
        
        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-control {
        z-index: 2;
    }
}











